export default {
 
  methods: {
    horaSait(){
      var f = new Date();

      var hora    = f.getHours()
      var minuto  = f.getMinutes()
      var segundo = f.getSeconds()
      
      if(hora < 10){
        hora = "0" + f.getHours()
      }
      if(minuto < 10){
        minuto = "0" + f.getMinutes()
      }
      if(segundo < 10){
        segundo = "0" + f.getSeconds()
      }

      return hora + ":" + minuto  +":" + segundo
      
    }
  }
}