<template>
  <v-container class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-1 pa-1" cols="12" xm="12">
        <!-- prepend-icon="mdi-magnify" -->
        <!-- append-outer-icon="mdi-magnify" -->

        <v-text-field
          v-model="buscando"
          hint="idcontacto"
          append-icon="mdi-check-circle"
          label="Nombre. Buscar por nombre o empresa"
          clearable
          :loading="loading"
          hide-details
          @click:append="buscarContacto"
          @keyup.enter="buscarContacto"
          @click:clear="limpiar"
          @keyup.113="buscarContacto"
        />

        <h4 v-if="idcontacto !== ''" class="mx-5 my-0">ID: {{ idcontacto }}</h4>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12" xm="12">
        <p v-if="contactos.length > 0" class="ma-1">
          <v-btn x-small @click="nuevo">
            <v-icon>mdi-pencil</v-icon>
            Nuevo
          </v-btn>

          {{ contactos.length }} resultados
        </p>

        <v-data-table
          v-if="contactos.length > 0"
          ref="tabla"
          class="grey lighten-2"
          :headers="headers"
          :items="contactos"
          item-key="idcontacto"
          :items-per-page="longitud"
          fixed-header
          dense
          :loading="loading"
          hide-default-header
          hide-default-footer
          height="300"
          @click:row="seleccionar(item)"
        >
          <template v-slot:item="{ item }">
            <tr @click.once="seleccionar(item)">
              <td>{{ item.idcontacto }}</td>
              <td>{{ item.nomcont }}</td>
              <td>{{ item.empresa }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  data: () => ({
    buscando: "",
    longitud: 30,
    loading: false,
    contactos: [],
    nomcont: "",
    idcontacto: "",
    headers: [
      { text: "Id", align: "start", value: "idcontacto" },
      { text: "Contacto", align: "end", value: "nomcont" },
      { text: "Empresa", align: "end", value: "empresa" }
    ]
  }),

  computed: {
    ...mapGetters("contactos", ["getContacto"])
  },

  watch: {
    getContacto() {
      // this.limpiar()
      this.actualizarLeecontactos()
    }
  },

  created() {
    this.buscando = ""
    this.idcontacto = ""
    if (this.getContacto.idcontacto == undefined) {
      this.buscando = ""
      this.idcontacto = ""
    } else {
      this.buscando = this.getContacto.nomcont
      this.idcontacto = this.getContacto.idcontacto
    }
  },

  methods: {
    ...mapActions("contactos", ["setContacto", "setNewContacto"]),
    ...mapActions("snackbar", ["showSnack"]),

    nuevo() {
      console.log("buscando", this.buscando)
      if (this.idcontacto === "") {
        // console.log("item", item)
        this.nomcont = this.buscando
        this.contactos = []
        this.setNewContacto(this.nomcont)
        let contacto = {
          nomcont: this.buscando,
          idcontacto: "",
          numcont: "",
          empresa: "",
          telefono: "",
          numcli: "",
          email: "",
          celular: "",
          sucursal: "",
          departamento: ""
        }
        this.setContacto(contacto)
      } else {
        this.nomcont = this.buscando
        this.setNewContacto(this.nomcont)
        this.contactos = []
      }
    },

    actualizarLeecontactos() {
      this.buscando = this.getContacto.nomcont
      this.nomcont = this.getContacto.nomcont
      this.idcontacto = this.getContacto.idcontacto
    },

    buscarContacto() {
      //ELIMINANDO BUSQUEDAS VACIAS
      if (this.buscando == null) return

      // console.log("a Buscar", this.buscando)
      var payload = {
        Buscacont: this.buscando.trim()
      }

      this.loading = true
      this.$http
        .post("v2/contacto.find", payload)
        .then(response => {
          console.log(response.data)
          this.loading = false

          if (response.data.Total > 0) {
            this.contactos = response.data.Data
            this.longitud = response.data.Total
            // console.log(" RESULTADOS",this.contactos )
          } else {
            this.setNewContacto(this.buscando)
            this.contactos = []

            console.log("SIN RESULTADOS")
            this.showSnack({
              text: "No se encontraron contactos",
              color: "info",
              timeout: 2000
            })
          }
        })
        .catch(() => {
          this.contactos = []
          this.loading = false
          // console.log()
        })
    },

    limpiar() {
      // Limpiar = nuevo. No seleccionar.

      this.contactos = []
      this.idcontacto = ""
      this.nomcont = this.buscando
      // this.buscando=
      // var item= {}
      let contacto = {
        nomcont: "",
        idcontacto: "",
        numcont: "",
        empresa: "",
        telefono: "",
        numcli: "",
        email: "",
        celular: "",
        sucursal: "",
        departamento: ""
      }
      this.setContacto(contacto)
    },

    seleccionar(item) {
      console.log("seleccionar", item)
      this.nomcont = item.nomcont
      this.idcontacto = item.idcontacto
      this.buscando = item.nomcont
      this.contactos = []
      this.setContacto(item)
    }
  }
}
</script>
