<template>
  <v-row justify="center">
    <v-col cols="9">
      <v-container>
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-toolbar
                dark
                color="teal"
              >
                <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                <v-toolbar-title>Seguimiento de una oportunidad</v-toolbar-title>
                <v-spacer />
                <v-btn
                  align="right"
                  color="red"
                  dark
                  class="ma-1"
                  @click="regresar"
                >
                  <v-icon>mdi-reply</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="6">
              <!-- CONTACTOS -->
              <leecontacto class="ma-2 pa-2" />

              <v-text-field
                v-model="empresa"
                outlined
                readonly
                dense
                prepend-icon="mdi-briefcase"
                hide-details
                class="ma-2"
                label="Empresa"
              />
	          
              <v-text-field
                v-model="email"
                outlined
                readonly
                dense
                prepend-icon="mdi-at"
                hide-details
                class="ma-2"
                label="Email"
              />
	        
              <v-text-field
                v-model="telefono"
                outlined
                readonly
                dense
                prepend-icon="mdi-phone"
                hide-details
                class="ma-2"
                label="Telefono"
              />
						
              <!-- LEE CLIENTE -->
              <!-- <leecliente class="ma-2 pa-2"></leecliente> -->
            </v-col>

            <v-col cols="6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha1"
                    outlined
                    class="ma-4"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    dense
                    hide-details
                    v-on="on"
                  />
                </template>
              </v-menu>

              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="tipoopor"
                    prepend-inner-icon="mdi-format-list-bulleted-type"
                    outlined
                    :items="tipos"
                    label="Tipo de Oportunidad"
                    dense
                    class="ma-0 pa-2"
                    clearable
                    hide-details
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="usuariogen"
                    prepend-inner-icon="mdi-account"
                    hide-details
                    dense
                    class=" ma-0 pa-2"
                    outlined
                    label="Usuario generador"
                  />
                  <v-spacer />
                </v-col>
              </v-row>

              <v-textarea
                v-model="obs"
                height="70"
                class="ma-1"
                outlined
                dense
                name="input-7-4"
                label="Descripcion de la oportunidad"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-spacer />
            <v-col cols="2" />
            <v-col>
              <v-btn
                color="blue"
                class="ma-2"
                dark
                @click="mostrarseguimiento"
              >
                Seguimiento
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="green"
                class="ma-2"
                dark
                @click="mostrarganada"
              >
                Ganada
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="red"
                class="ma-2"
                dark
                @click="mostrarperdida"
              >
                Perdida
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="2" />
          </v-row>
        </v-card>

        <!-- PARTE DE LOS BOTONES -->
        <v-card v-if="verseguimiento">
          <v-row>
            <v-col cols="4">
              <v-textarea
                v-model="seguireali"
                height="80"
                class="ma-4"
                outlined
                dense
                label="Seguimiento realizado"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                v-model="seguireali"
                height="80"
                class="ma-4"
                outlined
                dense
                label="Comentario del prospecto"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="probacierre"
                outlined
                :items="probabilidad"
                label="Probabilidad de cierre"
                dense
                class="ma-3"
                clearable
                hide-details
              />
              <v-row justify="center">
                <v-btn
                  class="ma-2"
                  color="orange"
                  dark
                  @click="validaInfo"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card v-if="verganada">
          <v-row>
            <v-spacer />
            <v-col cols="4">
              <v-text-field
                v-model="factura"
                class="ma-2"
                label="Folio de la Factura"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                dense
                hide-details
                v-on="on"
              />
            </v-col>
            <v-spacer />
            <v-col cols="3">
              <v-btn
                class="ma-2"
                color="orange"
                dark
                @click="validaInfo"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-card v-if="verperdida">
          <v-row>
            <v-spacer />
            <v-col cols="4">
              <v-textarea
                v-model="seguireali"
                height="80"
                class="ma-4"
                outlined
                dense
                label="¿Porque perdimos?"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                v-model="seguireali"
                height="80"
                class="ma-4"
                outlined
                dense
                label="¿Que podemos hacer parea mejorar?"
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                class="ma-10"
                color="orange"
                dark
                @click="validaInfo"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import leecontacto from '@/components/leecontacto'
// import leecliente from '@/components/leecliente'
import horaSait from '@/mixins/horasait'

export default {
  components:{
    leecontacto,
    // leecliente
  },
	  mixins: [horaSait],

  data(){
    return{
      tipos: ['Licencias', 'Nomina','Reservaciones','Sait Basico','Sait CI','Sait ERP','Soporte'],
      // tipo: ['Licencias', 'vencidas'],
      probabilidad: ['Aprobado', 'Dudoso','No cerrado'],
      tipo: ['Licencias', 'vencidas'],
      value: null,
      snackbar: false,
      verseguimiento: false,
      verganada: false,
      verperdida: false,
      minutos:'',
      time: this.hora(),

      fecha1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,

      usuariogen: "",
      asunto:'',
      numcont:'',
      empresa:'',
      telefono:'',
      email:'',
      tipoopor:'',
      obs:'',
      numcli:'',
      temas:'',
      numproy:'',
      idcontacto: 0,
      idcita:'',
      nModo: 1,
    }
  },

  computed: {
    ...mapGetters('contactos', ['getContacto']),

    validaInfo(){
      return  this.leecontacto   !== ''  &&
      this.empresa !== '' &&
      this.email !== '' &&
      this.telefono !== '' &&
      this.leecliente !== '' &&
      this.tipoopor  !== '' &&
      this.asunto  !== '' &&
      this.conocioen !== '' &&
      this.obs !== ''
    },
  },
		
  watch: {
    getContacto(){
      this.actualizarDatoscontactos()
    },
  },

  created(){
    console.log('router', this.$route.params.cita )

    let ruta = this.$route.params.cita

	    //MODO EDITAR
	    if ( ruta !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.CargarInfo(this.$route.params.cita)
	        this.modo = 'Editar'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
	    }

  },

  methods:{
    // ...mapActions('citas',['addCita']),
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions('contactos',['setContacto','limpiarContacto']),

    CargarInfo(oportunidad){
      console.log("oportunidad", oportunidad)

      this.leecontacto= oportunidad.leecontacto
      this.empresa = 	oportunidad.empresa
      this.email =	oportunidad.email
      this.telefono=  oportunidad.telefono
      this.leecliente= oportunidad.leecliente
      this.tipoopor= oportunidad.tipoopor
      this.asunto = oportunidad.asunto
      this.conocioen = oportunidad.conocioen
      this.obs=  oportunidad.obs

    },

    mostrarseguimiento(){
      this.verseguimiento  = true
      this.verganada       = false
      this.verperdida      = false
    },
    mostrarganada(){
      this.verganada       = true
      this.verseguimiento  = false
      this.verperdida      = false
    },
    mostrarperdida(){
      this.verperdida      = true
      this.verganada       = false
      this.verseguimiento  = false
    },


    agregar(){
				
      //VALIDAR INFORMACION
		    if(!this.validaInfo){
		    	 this.showSnack({
          text: "Debes registrar el asunto, los temas y la fecha",
          color: "orange",
          timeout: 3000,
        });

		    	console.log("FALLA EN LLENAR")
		        // this.snackbar = true ; this.color="orange"
		        // this.text = "Faltan llenar Datos. Nombre, email, telefono"
		      return
		    }


      console.log("fecha",this.fecha)
      this.fechaend = this.fecha + ' '+ this.hora
      this.fechastart = this.fecha + ' '+ this.hora

      var payload = {
        leecontacto: this.leecontacto,
        empresa: 	this.empresa,
        telefono: 	this.telefono,
        leecliente:  this.leecliente,
        tipoopor: this.tipoopor,
        asunto: this.asunto,
        conocioen:  this.conocioen,
        obs:  this.obs,
      }

      console.log("payload", payload)

	
	     //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http.post('v2/insert.cita', payload).then(response =>{
			        this.showSnack({
		            text: "Cita Agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

			        this.limpiarCita()
	            // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
            	// this.snackbar = true ;
	            // this.color="error"
	            // this.text = "Problemas para grabar el cliente"
	            console.log(error)
         			this.showSnack({
		            text: "Problema para agendar Cita."+ error,
		            color: "error",
		            timeout: 2000,
		          });
     
        })
        
      } else {
          
        // EDICION DE UNA CITA.
        this.$http.put('v2/update.cita' + this.idcita, payload).then(response =>{
          this.showSnack({
		            text: "Cita Actualizada correctamente."+ error,
		            color: "error",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'catcitas'}),2000);

        }).catch(error=>{
            	this.showSnack({
		            text: "Problema para agendar Cita."+ error.error,
		            color: "error",
		            timeout: 2000,
		          });
          console.log(error)
        })

      }


    },
    limpiarCita(){
      this.leecontacto = ''
      this.empresa = ''
      this.telefono = ''
      this.leecliente = ''
      this.tipoopor= ''
      this.asunto= ''
      this.conocien =''
      this.obs = ''
					
    },
   				
   	  regresar(){
      console.log("ruta", this.$route.name)

      // MODO PANEL
      if (this.$route.name == 'panel') {
        if (this.idcita == undefined){
          console.log("cerrar vista modal y limipiar. ")
          // this.$router.push({name:'panel'}).catch (err => {console.log(err)})
           
        } else {
          console.log("Catalogo regresar")
          this.$router.push({name:'catoportunidades'})
        }
      }

      // MODO CATALOGO
      if (this.$route.name == 'seguimiento' ){
        this.$router.push({name:'catoportunidades'})
      }

      
    },

    hora(){
      var f =new Date();
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if(hora < 10){
        hora = "0" + f.getHours()
      }
      if(minuto < 10){
        minuto = "0" + f.getMinutes()
      }
      if(segundo < 10){
        segundo = "0" + f.getSeconds()
      }
        
      return hora + ":" + minuto + ":" + segundo
    },
			
    actualizarDatoscontactos() {
      this.celular = this.getContacto.celular
      this.empresa = this.getContacto.empresa
      this.idcontacto = this.getContacto.idcontacto

      this.numcli = this.getContacto.numcli
        
      this.numcont = this.getContacto.numcont
      this.sucursal = this.getContacto.sucursal
      this.telefono = this.getContacto.telefono
        
      this.departamento = this.getContacto.departamento

    },

  }
}
</script>